import React, { useReducer, useRef, useState } from 'react';
import { Pg2 } from '../../code/reducer';
import { fnVal } from '../../code/val';
import { Loading } from '../../components/loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './page2.css'

export const Page2 = () => {

    const formRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const rules = {
        doc: {
            required: true,
            error: 'El documento es obligatorio'
        },
        nombres: {
            required: true,
            min: 5,
            error: 'El nombre es obligatorio y debe tener al menos 5 caracteres'
        },
        apellidos: {
            required: true,
            min: 5,
            error: 'El apellido es obligatorio y debe tener al menos 5 caracteres'
        },
        celular: {
            required: true,
            error: 'El celurar es obligarotio'
        }
    };

    const isValid = (name) => {
        if (state.errorFields.includes(name))
            return 'is-invalid';
        return '';
    }


    const [state, dispatch] = useReducer(Pg2.reducer, Pg2.init);

    const inputChanged = (evt) => {
        dispatch({
            type: 'Change', payload: {
                name: evt.target.name,
                value: evt.target.value
            }
        });
    }

    const onReset = () => {
        dispatch({ type: "Reset" });
    }

    const notify = () => toast("La cita se agendo correctamente!",
        {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            draggable: true,
            pauseOnHover: false,
            progress: 0,
            type: 'success',
            theme: 'light',
            onClose: () => setLoading(false)
        });

    const onSubmit = (evt) => {
        evt.preventDefault();
        
        const errors = fnVal.validate(rules, state.data);
        dispatch({ type: 'ErrorFields', payload: errors });

        if (errors.length > 0) return;

        setLoading(true);

        dispatch({ type: 'Loading' });

        JSON.stringify(state.data)

        fetch('https://landaasoc.com/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(state.data)
        }).then(res => {
            console.log(res);
            if (res.status == 200) {
                dispatch({ type: 'Complete' });
                notify();
            } else {
                dispatch({ type: 'Error' });
                setLoading(false);
                res.json().then(o => alert(o));
            }
        }).catch(err => {
            dispatch({ type: 'Error' });
            setLoading(false);
            alert("Ocurrio un error al acceder a la api");
        });

        evt.target.reset();
    }

    const _onFocus = (e) => {
        e.currentTarget.type = "date";
    }

    const _onBlur = (e) => {
        if (!!!e.currentTarget.value) {
            e.currentTarget.type = "text";
            e.currentTarget.placeholder = "Fecha de Nacimiento";
        }
    }

    return (
        <>
            <div id="citas">
                <form className='form' onSubmit={onSubmit} onReset={onReset}
                    ref={formRef} >
                    <div className='form-content'>
                        <a name="cita">
                            <div className='form-title'>Agendar Consulta</div>
                        </a>
                        <div className='row'>
                            <div className='col-sm-4 col-main'>
                                <div className='step'>
                                    Por favor mándenos sus datos para contactarnos con usted y programar su cita.
                                </div>
                                <input type="text" name='DNI' onChange={inputChanged} className={`form-control ${isValid('DNI')}`} placeholder="Doc. Identidad*" ></input>
                                <input type="text" name='Nombre' onChange={inputChanged} className={`form-control ${isValid('Nombre')}`} placeholder="Nombres*"></input>
                                <input type="text" name='Apellidos' onChange={inputChanged} className={`form-control ${isValid('Apellidos')}`} placeholder="Apellidos*"></input>
                                <input type="tel" name='Celular' onChange={inputChanged} className={`form-control ${isValid('Celular')}`} placeholder="Celular para consulta*"></input>
                                <input type="email" name='Mail' onChange={inputChanged} className={`form-control ${isValid('Mail')}`} placeholder="Correo Electrónico"></input>
                            </div>
                            <div className='col-sm-8'>
                                <div className='step-2'>
                                    Si es su primera consulta con nosotros, necesitamos algunos datos más
                                    para registrar su Historial Clínica.
                                </div>
                                <div className='row'>
                                    <div className='col-sm-6 col-main'>
                                        <input type="text" name='FechaNac' onFocus={_onFocus} onBlur={_onBlur} onChange={inputChanged} className="form-control" placeholder="Fecha de Nacimiento" style={{ color: 'rgb(9, 108, 147)' }}></input>
                                        <input type="text" name='LugarNac' onChange={inputChanged} className="form-control" placeholder="Lugar de Nacimiento"></input>
                                        <input type="text" name='DirActual' onChange={inputChanged} className="form-control" placeholder="Dirección Actual"></input>
                                        <input type="text" name='Ciudad' onChange={inputChanged} className="form-control" placeholder="Ciudad"></input>
                                    </div>
                                    <div className='col-sm-6 col-main'>
                                        <div className='col-space'></div>
                                        <div className='row'>
                                            <div className='col'>
                                                <select value={state.data.EstadoCivil} name='EstadoCivil' className="form-select" onChange={inputChanged} >
                                                    <option value=''>Estado Civil</option>
                                                    <option value={0}>No definido</option>
                                                    <option value={1}>Soltero(a)</option>
                                                    <option value={2}>Casado(a)</option>
                                                    <option value={3}>Conviviente</option>
                                                    <option value={4}>Divorciado(a)</option>
                                                    <option value={5}>Viudo(a)</option>
                                                </select>
                                            </div>
                                            <div className='col'>
                                                <input type="number" min={0} name='NroHijos' onChange={inputChanged} className='form-control' placeholder='Nro hijos'></input>
                                            </div>
                                        </div>
                                        <select value={state.data.GradoInst} name='GradoInst' className="form-select" onChange={inputChanged}>
                                            <option value=''>Grado de Inst.</option>
                                            <option value={0}>Ninguno</option>
                                            <option value={1}>Primaria sin terminar</option>
                                            <option value={2}>Primaria completa</option>
                                            <option value={3}>Secundaria sin terminar</option>
                                            <option value={4}>Secundaria completa</option>
                                            <option value={5}>Técnico</option>
                                            <option value={6}>Universitaria</option>
                                        </select>
                                        <input type="text" name='Ocupacion' onChange={inputChanged} className="form-control" placeholder="Ocupación"></input>
                                    </div>
                                    <div className='footer'>
                                        Sus datos solamente serán usados por el médico para dar seguimiento a su condición
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='actions'>
                        <button disabled={loading} type="reset" className="btn btn-outline-primary">Borrar</button>
                        <button disabled={loading} type="submit" className="btn btn-primary">Pedir Cita</button>
                    </div>
                    <Loading visible={state.loading} />
                </form>
            </div>
            <ToastContainer />
        </>
    );
}