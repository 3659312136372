import './page3.css'

export const Page3 = () => {

    return (
        <div id="preguntas">
            <div className='content'>
                <div className='title-preg'>
                    Preguntas Frecuentes
                </div>
                <div className='preg-group'>
                    <div className='preg'>
                        ¿Cómo funciona una video consulta?
                    </div>
                    <div className='resp'>
                        En muchos casos mejor que una presencial. Para disfrutar de toda la calidez y privacidad de la entrevista, debe <a href='#cita'>solicitar la consulta</a> enviando sus datos personales y una nota de la razón o dolencia por la cual consulta. En breve será contactado para establecer su turno de atención en línea.
                    </div>
                </div>
                <div className='preg-group'>
                    <div className='preg'>
                    ¿Cuál es la ventaja de una video consulta?
                    </div>
                    <div className='resp'>
                    La persona consulta del ambiente seguro de su hogar, asistido por su familiar de confianza y recibe más tiempo y calidez en la entrevista, aun si vive en otro lugar remoto, otra región u otro país, habrá ahorrado en traslado, alimentación, pérdida de sus días de trabajo, sin riesgos de contagio en el viaje o en la sala de espera.
                    </div>
                </div>
                <div className='preg-group'>
                    <div className='preg'>
                        ¿Cuándo es necesario una consulta presencial?
                    </div>
                    <div className='resp'>
                    Cuando en la video consulta se hace evidente que necesita un examen exploratorio que no puede hacerse por video. Ejemplo, si requiere laboratorio o imágenes de ayuda diagnostica, habrá ganado tiempo y vendrá a la presencial con todo y no, como usualmente ocurre en el turno presencial que la persona ya no está en ayunas y tendrá que ir otro día al laboratorio y obtener otra cita para evaluar sus resultados y concluir su consulta.
                    </div>
                </div>
                <div className='preg-group'>
                    <div className='preg'>
                    ¿Por qué es necesaria toda la información solicitada antes de una cita?
                    </div>
                    <div className='resp'>
                    Porque así la persona puede contar con un registro clínico para datos importantes de su estado de salud, que ayudan definir mejor su diagnóstico y prescribir sus recetas, indicaciones y exámenes auxiliares, dando precisión a la memoria del médico en su seguimiento y evaluación posterior. La información sólo es accesible al médico tratante y le pertenece a la persona, no a la institución pública o privada, y puede solicitarla para los fines que requiera.
                    </div>
                </div>
            </div>
        </div>
    );
}