import './header.css';

export const Header = () => {

    return (
        <>
            <div id='navbar' className='nav-bar'>
                <div className='header'>
                    <div className='subTitle'>
                        <span>Consultorios</span>
                        <span>Médicos</span>
                    </div>
                    <div className='div-vert'></div>
                    <a href='#inicio'>
                    <img className='logo' src='/images/landa.png'></img>
                    </a>
                </div>
                <div className='menu'>
                    <a href='#preguntas' className='link'>Preguntas Frecuentes</a>
                    <a href='#citas' className='link'>Agendar Consulta</a>
                </div>
            </div>
        </>
    );
}