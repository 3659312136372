import './page1.css'

export const Page1 = () => {

    return (
        <div id="inicio">
            <div id='content-page1'>
                <div className='title-img'>MEDICINA GENERAL APLICADA A LA FAMILIA Y AL ADULTO MAYOR</div>
                <div className='img-content'>
                    <img className='img' src='/images/adultos.jpeg'>
                    </img>
                    <a href='#citas'>
                        <div className='lnk-img'></div>
                    </a>
                </div>
                <div id='content-bottom' className='row t-group m-1'>
                    <div className='col-sm-4 p-0'>
                        <img className='img-fluid' src='/images/atencion.png'></img>
                    </div>
                    <div className='col-sm-8'>
                        <span className='t-bottom'>Lo que nos distingue</span>
                        <span className='t-content'>
                            Procuramos el bienestar físico, emocional y relacional de nuestros pacientes brindando
                            una atención médica integral enfocada en la persona humana total.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}