

export const Loading = ({ visible }) => {
    var st = {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        backgroundColor: 'black',
        backgroundColor: 'rgba(255,255,255,0.4)',
        backdropFilter: 'blur(2px)',
    };

    var sb = {
        width: '3rem',
        height: '3rem'
    };

    if (!!!visible)
        return <></>;

    return (
        <div style={st}>
            <div className="spinner-border text-dark" style={sb} role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
}