
export const Pg2 = {
    init: {
        loading: false,
        errorFields: [],
        data: {
            DNI: '',
            Nombre: '',
            Apellidos: '',
            Celular: '',
            Mail: '',
            FechaNac: null,
            LugarNac: '',
            DirActual: '',
            Ciudad: '',
            EstadoCivil: null,
            NroHijos: null,
            GradoInst: null,
            Ocupacion: ''
        }
    },
    reducer: (state, { type, payload }) => {
        switch (type) {
            case "Error":
                return {
                    ...state,
                    loading: false,
                };
            case "Reset":
                return Pg2.init;
            case "ErrorFields":
                return {
                    ...state,
                    errorFlds: payload
                }
            case "Change":
                return {
                    ...state,
                    data: {
                        ...state.data,
                        [payload.name]: payload.value
                    }
                };
            case "Loading":
                return {
                    ...state,
                    loading: true
                }
            case "Complete":
                return Pg2.init
        }

        return state;
    }
}
