import { Header } from '../components/header';
import './main.css';
import { Page1 } from './page1/Page1';
import { Page2 } from './page2/Page2';
import { Page3 } from './page3/Page3';

export const MainPage = () => {

    return (
        <div className='main'>
            <Header />
            <div className='container'>
                <Page1 />
                <Page2 />
                <Page3 />
            </div>
            <div className='fra-green'>
                <div style={{ maxWidth: "800px", textAlign: 'center' }}>
                    "La imaginación es la mitad de la enfermedad; la tranquilidad es la mitad del remedio; y la paciencia es el comienzo de la cura."
                </div>
                <div style={{ maxWidth: "800px", width: '100%', textAlign: 'right', fontSize: '12px', paddingRight: '10px' }}>
                    -lbn Sina (Avicena), Célebre sabio y filósofo
                </div>
            </div>
            <div className='fra-blue'>
                <div>
                    <div>
                        <img src='/images/phone.png' height={24}></img>
                        <a href='tel: +51942631217'>+51 942 631 217 (Sólo WhatsApp)</a>
                    </div>
                    <div>
                        <img src='/images/mail.png'></img>
                        <a href='mailto:doctoreslanda@gmail.com'>doctoreslanda@gmail.com</a>
                    </div>
                    <div>
                        <img src='/images/location.png'></img>
                        <a href='https://www.google.com/maps/place/Jiron+Alonso+de+Alvarado+1474,+Moyobamba+22001,+Per%C3%BA'>Jirón Alonso de Alvarado 1474, Moyobamba - Perú</a>
                    </div>
                </div>
            </div>
        </div>
    );
}