export const fnVal = {
    fn: {
        email: (val) => {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val)) {
                return (true)
            }
            return (false)
        },
        phone: (val) => {
            var phoneRe = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
            var digits = val.replace(/\D/g, "");
            return phoneRe.test(digits);
        },
        max: (val, length) => {
            if (!!!val) return false;
            return val.length <= length;
        },
        min: (val, length) => {
            if (!!!val) return false;
            return val.length >= length;
        },
        range: (val, min, max) => {
            if (!!!val) return false;

            return val.length >= min && val.length <= max;
        },
        required: (val) => {
            return !!val;
        }
    },
    validate: (rules, data) => {
        var rulesAct = Object.entries(rules);
        var result = [];

        for (let i in rulesAct) {
            const item = rulesAct[i];
            const fieldName = item[0];
            const rule = item[1]

            if (!data.hasOwnProperty(fieldName))
                continue;

            var value = data[fieldName];
            var resVal = undefined;

            if (rule.required) {
                resVal = fnVal.fn.required(value);
                if (!resVal) {
                    result.push({
                        [fieldName]: rule.error
                    });
                    continue;
                };
            }
            if (rule.min) {
                resVal = fnVal.fn.min(value, rule.min);
                if (!resVal) {
                    result.push({
                        [fieldName]: rule.error
                    });
                    continue;
                };
            }
            if (rule.max) {
                resVal = fnVal.fn.max(value, rule.max);
                if (!resVal) {
                    result.push({
                        [fieldName]: rule.error
                    });
                    continue;
                };
            }
            if (rule.email) {
                resVal = fnVal.fn.email(value);
                if (!resVal) {
                    result.push({
                        [fieldName]: rule.error
                    });
                    continue;
                };
            }
            if (rule.phone) {
                resVal = fnVal.fn.phone(value);
                if (!resVal) {
                    result.push({
                        [fieldName]: rule.error
                    });
                    continue;
                };
            }
            if (rule.range) {
                resVal = fnVal.fn.range(value, rule.min, rule.max);
                if (!resVal) {
                    result.push({
                        [fieldName]: rule.error
                    });
                    continue;
                };
            }
        }

        return result;
    }
};
